<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营商管理</el-breadcrumb-item>
      <el-breadcrumb-item>运营商列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体卡片视图区域 -->
    <el-card class="box-card">
      <!-- 上栅格布局  -->
      <el-row :gutter="10">
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-input placeholder="请输入内容" v-model="DevicesListInfo.query" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="getDevicesList"></el-button>
          </el-input>
        </el-col>
        <!--        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">-->
        <!--          <el-select v-model="selectValue" filterable placeholder="请选择">-->
        <!--            <el-option-->
        <!--                v-for="item in options"-->
        <!--                :key="item.selectValue"-->
        <!--                :label="item.label"-->
        <!--                :value="item.selectValue">-->
        <!--              <span style="float: left">{{ item.label }}</span>-->
        <!--              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.selectValue }}</span>-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog =true" v-has="'POST_UserPermission'">添加</el-button>
          <el-button type="success" icon="el-icon-refresh-right" @click="update()">刷新</el-button>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">

        </el-col>
      </el-row>

      <!-- 下渲染列表区域 -->
      <transition name="el-fade-in-linear">
        <el-table :data="AgentList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="角色组" prop="DeviceId"></el-table-column>
          <el-table-column label="角色名" prop="DeviceName"></el-table-column>
          <el-table-column label="主要权限" prop="DeviceSecret"></el-table-column>
          <el-table-column label="权限描述" prop="DeviceSecret"></el-table-column>
          <el-table-column label="操作" width="170px">
            <template >
              <el-button type="warning" icon="el-icon-setting" @click="editDialog=true">编辑</el-button>
            </template>
          </el-table-column>

        </el-table>
      </transition>    </el-card>
  </div>
</template>

<script>
export default {
  name: "Agent",
  data() {
    return{
      AgentList:[
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'超级管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'超级管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'管理员',

        }
      ],
    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
